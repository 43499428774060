import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const BusinessIntelligencePage = props => {
    const { t } = useTranslation();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="business-intelligence-page:pageTitle">Business Intelligence</Trans>}
            subtitle={<Trans i18nKey="business-intelligence-page:pageSubtitle">View, analyze, predict: all in a single
                                                                               platform</Trans>}
            cover="overview"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="business-intelligence-page:pageDescription">
                <p>The AIDA Dashboard revolutionizes document and data management with a fully customizable, <strong>widget-based
                                                                                                                     dashboard</strong>.<br />
                   Add, resize, and position widgets to visualize real-time analytics, monitor activities, and access
                   advanced business intelligence tools.</p>
                <p>Leverage dynamic charts that <strong>automatically update</strong> with new document uploads, and
                   unlock <strong>predictive insights</strong> through trend analysis and seasonal forecasts.<br />
                   From monitoring ROI and time savings to managing payments with AIDA Pay, Panoramica integrates
                   seamlessly into your workflow.</p>
                <p>Transform raw data into actionable insights and <strong>stay ahead</strong> with a platform designed
                   for efficiency and strategic growth.</p>
            </Trans>
        </div>
        <div className={clsx(classes.FeaturesContainer)}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/business-intelligence/aida-business-intelligence-01-foundation.jpg"
                            alt="AIDA - Business Intelligence - The perfect foundation for Business Intelligence" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('business-intelligence-page:pageTitle')}</div>
                            <h2><Trans>The perfect foundation for Business Intelligence</Trans></h2>
                            <Trans i18nKey="business-intelligence-page:feature1">
                                <p>With AIDA, <strong>Business Intelligence</strong> originates from <strong>Document
                                                                                                             Management</strong>.<br />
                                   Data extracted from documents are normalized and organized, eliminating duplicates
                                   and ensuring a <strong>clean and reliable</strong> database.
                                   This structure allows for the creation of reports and analyses without the need for
                                   complex manual interventions.</p>
                                <p>Thanks to data integration, you can monitor KPIs, analyze trends, or compare
                                   historical metrics with utmost ease. For example, quickly identify your most
                                   profitable customers or analyze operational costs to optimize your business
                                   strategy.</p>
                                <p>The certainty of having up-to-date data in real time, without errors or duplications,
                                   allows you to save time and focus your resources on strategic decisions, <strong>turning
                                                                                                                    every
                                                                                                                    document
                                                                                                                    into
                                                                                                                    a
                                                                                                                    valuable
                                                                                                                    asset</strong>.
                                </p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('business-intelligence-page:pageTitle')}</div>
                            <h2><Trans>Dynamic and easy-to-create charts</Trans></h2>
                            <Trans i18nKey="business-intelligence-page:feature2">
                                <p>Creating charts with AIDA does not require technical skills.<br />
                                   With <strong>just a few clicks</strong>,
                                   you can choose from pie charts, bar charts, line charts, and data aggregations to
                                   visually represent your insights.<br />
                                   Each chart is intuitive and immediate, designed to adapt to the needs of each user.
                                </p>
                                <p>For example, you can create a chart that displays total revenue per customer,
                                   automatically updated every time you upload new documents.<br />
                                   Alternatively, you can monitor the progress of your contracts, quarter by quarter.
                                </p>
                                <p><strong>Automatic updating</strong> ensures that your charts are always in line with
                                                                       the latest data status, eliminating the need for
                                                                       manual intervention.<br />
                                                                       This is an invaluable advantage for anyone who
                                                                       wants to make quick and informed decisions.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/business-intelligence/aida-business-intelligence-02-dynamic-charts.jpg"
                            alt="AIDA - Business Intelligence - Dynamic and easy-to-create charts" />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/business-intelligence/aida-business-intelligence-03-forecast.jpg"
                                     alt="AIDA - Business Intelligence - Anticipate the future with historical data" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('business-intelligence-page:pageTitle')}</div>
                            <h2><Trans>Anticipate the future with historical data</Trans></h2>
                            <Trans i18nKey="business-intelligence-page:feature3">
                                <p>AIDA's charts do not merely represent the present but also analyze historical data to
                                   provide <strong>forecasts about future trends</strong>.<br />
                                   This system leverages the evolution of historical data to identify <strong>patterns,
                                                                                                              trends,
                                                                                                              and
                                                                                                              seasonality</strong>,
                                   allowing you to precisely plan your next moves.</p>
                                <p>For example, a retail company can predict sales spikes during the holiday season or
                                   identify slow periods to optimize inventory.<br />
                                   Similarly, in the service sector, you can anticipate periods of high demand and
                                   allocate resources more efficiently.</p>
                                <p>Thanks to these forecasts, based on intelligent algorithms that <strong>learn from
                                                                                                           your
                                                                                                           data</strong>,
                                   you will never be unprepared for market changes.<br />
                                   AIDA enables you to <strong>transform Business Intelligence into a proactive
                                                               tool</strong>, optimizing management and reducing the
                                   risks associated with decisions made in the dark.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('business-intelligence-page:pageTitle')}</div>
                            <h2><Trans>Manage payments directly from your dashboard</Trans></h2>
                            <Trans i18nKey="business-intelligence-page:feature4">
                                <p>The <Link to="/platform/aida-pay">AIDA Pay</Link> widget offers a clear and
                                   comprehensive overview of received, pending, and anticipated payments.<br />
                                   You can monitor each transaction and access tools for <strong>automatic
                                                                                                 reconciliation</strong>.
                                </p>
                                <p>For example, view payments collected per customer or by date, or use the data to
                                   forecast cash flow.<br />
                                   Everything is seamlessly connected to <Link to="/platform/relations-knowledge-graph">related
                                                                                                                        documents</Link>,
                                   eliminating errors and simplifying financial control.</p>
                                <p>With AIDA Pay, you transform every document into a window into your
                                   finances, <strong>combining document management and transactions</strong> into a
                                   single integrated solution.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage src="../../images/business-intelligence/aida-business-intelligence-04-payments.jpg"
                                     alt="AIDA - Business Intelligence - Manage payments directly from your dashboard" />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/business-intelligence/aida-business-intelligence-05-roi-productivity.jpg"
                            alt="AIDA - Business Intelligence - Measure the impact of AIDA on your productivity" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('business-intelligence-page:pageTitle')}</div>
                            <h2><Trans>Measure the impact of AIDA on your productivity</Trans></h2>
                            <Trans i18nKey="business-intelligence-page:feature5">
                                <p>With the <strong>Boost</strong>, <strong>ROI</strong>, and <strong>Time
                                                                                                      Saved</strong> widgets,
                                   you can quantify the benefits of your investment in AIDA.<br />
                                   Discover in real time how much time you've saved compared to manual processes and
                                   calculate the economic return generated by automation.</p>
                                <p>For example, a user who uses AIDA daily can save <strong>hours each week</strong>,
                                   improving the overall efficiency of the team.<br />The widget provides tangible data
                                   that highlights the added value of the platform.</p>
                                <p>These tools allow you not only to monitor progress but also to identify areas for
                                   improvement to <strong>maximize the return on investment</strong>.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default BusinessIntelligencePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "business-intelligence-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'business-intelligence-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Overview - Business Intelligence';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
